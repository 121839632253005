import React from "react";
import styled from "@emotion/styled";
import { graphql, Link } from "gatsby";
import {ICaseStudyFeature, IService} from "../utils/types";
import Layout from "../components/layout/layout";
import PageHeader from "../components/pageHeader";
import Container from "../components/container";
import downloadImage from "../images/download-9-STEP.jpg";
import { IDownloadDetail } from "../utils/types";
import BoxLink from '../components/boxLink';
import { theme } from '../utils/theme';
import Services from "../components/home/services";

interface DownloadDetail {
  data: {
    allDownloads: IDownloadDetail
  }
}

const DownloadCard = styled.div<any>`
  border: 1px solid ${props => props.theme.borderColor};
  text-align: left;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  font-weight: normal;
  margin-bottom: 137px;

    img {
      width: 100%;
      margin: 0;
    }

    h1 {
      margin: 0 0 26px;
      font-size: 24px;
      font-family: ${theme.headerFontFamily};
      font-weight: 400;
    }

    p {
      margin: 0 0 34px;
      font-size: 18px;
      line-height: 23px;
    }
`;

const DownloadContent = styled.div<any>`
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #707070;
  min-height: 227px;
  margin: 8px;
  padding: 20px;

  @media (min-width: ${props => props.theme.md}px) {
    padding: 40px;
    h2 {
      max-width: 70%;
    }
`;

export default function DownloadDetail(props : IDownloadDetail): JSX.Element {

  const { 
    id,
    slug,
    title,
    summary,
    fileDownload,
    key
  } = props.data.downloads;

  const {
    servicesTagline,
    servicesTaglineUnderline,
    servicesSubhead,
    featuredServices
  } = props.data.contentfulHomePage;
  
  const services: Array<IService> = featuredServices.map(s => ({
    name: s.serviceName,
    slug: s.serviceSlug,
    definition: s.serviceDefinition,
    shortDescription: s.serviceShortDescription,
    tactics: s.tactics || []
  }));

return (
  <Layout title="Download" >
    <PageHeader
        title={`Download: ${title}`}
        titleSubtext="Strategica Download Library"
        noSeperator
      />
      <Container>
        <DownloadCard to={fileDownload}>
          <Link to={fileDownload}><img src={downloadImage} alt="Strategica Hero Image"/></Link>
          <DownloadContent>
            <h1>{title}</h1>
            <p>{summary}</p>
            <BoxLink
              big
              textColor={theme.primaryColor}
              borderColor={theme.primaryColor}
              to={fileDownload}
              >
                  Download
            </BoxLink>
          </DownloadContent>
        </DownloadCard>
      </Container>
        <Services
          servicesTagline={servicesTagline}
          servicesTaglineUnderline={servicesTaglineUnderline}
          servicesSubhead={servicesSubhead}
          services={services}/>
  </Layout>
  )
}

export const query = graphql`
query($slug: String!) {
  downloads(slug: {eq: $slug}) {
    id
    slug
    title
    summary
    fileDownload
    key
  }
  contentfulHomePage {
    servicesTagline
    servicesTaglineUnderline
    servicesSubhead
    featuredServices {
        serviceName
        serviceSlug
        serviceDefinition
        serviceShortDescription
        serviceTactics
    }
  }
}
`;
