import React from "react";
import styled from "@emotion/styled";
import { useStaticQuery, graphql, Link} from "gatsby";

import {IService} from "../../utils/types";
import Container from "../container";
import Underliner from "../underliner";
import {md} from "../../utils/breakpoints";

const ServicesContainer = styled.div`
    margin-bottom: 100px;
`;

const MainWave = styled.img<any>`
    width: 100%;
    margin: 0;
    display: block;
    position: relative;
`;

const SubWave = styled.img<any>`
    margin: 0;
    position: absolute;
    bottom: ${props => props.bottom ? "auto" : 0};
    top: ${props => props.bottom ? 0 : "auto"};
    left: 0;
`;

const Waves = styled.div<any>`
    position: relative;
`;

const ServicesContent = styled.div<any>`
    background: ${props => props.theme.primaryColor};
    color: #fff;
    padding: 20px 0;
`;

const Tagline = styled.h1`
    font-size: 36px;
    font-weight: 400;
    color: #fff;
    display: block;
    margin: 0px auto 80px;
    text-align: center;
    line-height: 1.4;

    @media (max-width: ${md}px) {
        font-size: 26px;
    }
`;

const Subhead = styled.h2<any>`
    font-size: 24px;
    font-weight: 400;
    font-family: ${props => props.theme.contentFontFamily};
    color: #fff;
    margin: 0 auto 20px;
    text-align: center;
    display: block;
    width: 100%;
    line-height: 1.4;

    @media (max-width: ${md}px) {
        font-size: 18px;
    }
`;

const ServiceLink = styled(Link)<any>`
    margin: 0 auto 40px;
    max-width: 1000px;
    background: transparent;
    display: block;
    border: 1px solid #fff;
    text-decoration: none;
    padding: 10px;
    transition: border-color .2s ease-in-out;

    &:hover {
        border-color: ${props => props.theme.secondaryColor};
    }
`;

const ServiceContent = styled.span<any>`
    display: block;
    background: #fff;
    padding: 40px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
`;

const ServiceLeftContent = styled.span<any>`
    flex: 0 0 300px;
`;

const ServiceRightContent = styled.span<any>`
    @media (min-width: ${md}px) {    
        width: calc(100% - 300px);
    }

    @media (max-width: ${md}px) {    
        margin-top: 20px;
        width: 100%;
    }
`;

const ServiceName = styled.span<any>`
    display: block;
    color: ${props => props.theme.secondaryColor};
    font-family: ${props => props.theme.headerFontFamily};
    font-size: 48px;

    @media (max-width: ${md}px) {    
        font-size: 38px;
    }
`;

const ServiceDefinition = styled.span<any>`
    display: block;
    color: ${props => props.theme.primaryColor};
    font-size: 24px;

    @media (max-width: ${md}px) {    
        font-size: 16px;
    }
`;

const ServiceShortDescription = styled.span<any>`
    display: block;
    color: ${props => props.theme.primaryColor};
`;

const ServiceLearnMore = styled.span<any>`
    display: block;
    color: ${props => props.theme.primaryColor};
    text-decoration: underline;
    margin-top: 20px;
    text-transform: uppercase;
`;

export interface IServicesProps {
    services: Array<IService>;
    servicesTagline: string;
    servicesTaglineUnderline?: string;
    servicesSubhead: string;
}

export default function Services(props: IServicesProps): JSX.Element {
    const {
        services,
        servicesTagline,
        servicesTaglineUnderline,
        servicesSubhead
    } = props;

    const data = useStaticQuery(graphql`
        query {
            topMainWaveFile: file(relativePath: { eq: "home-services-top-main-wave.svg" }) {
                publicURL   
            }
            bottomMainWaveFile: file(relativePath: {eq: "home-services-bottom-main-wave.svg"}) {
                publicURL
            }
            topSubWaveFile: file(relativePath: { eq: "home-services-top-sub-wave.png" }) {
                childImageSharp {
                    fixed(width: 775, height: 118) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
            bottomSubWaveFile: file(relativePath: { eq: "home-services-bottom-sub-wave.png" }) {
                childImageSharp {
                    fixed(width: 1277, height: 122) {
                        ...GatsbyImageSharpFixed
                    }
                }
            }
        }
    `);

    const topMainWaveURL = data.topMainWaveFile.publicURL;
    const bottomMainWaveURL = data.bottomMainWaveFile.publicURL;
    const topSubWaveURL = data.topSubWaveFile.childImageSharp.fixed.src;
    const bottomSubWaveURL = data.bottomSubWaveFile.childImageSharp.fixed.src;

    return (
        <ServicesContainer>
            <Waves>
                <SubWave src={topSubWaveURL} />
                <MainWave src={topMainWaveURL} />
            </Waves>
            <ServicesContent>
                <Container>
                    <Subhead>{servicesSubhead}</Subhead>
                    <Tagline><Underliner text={servicesTagline} underline={servicesTaglineUnderline}/></Tagline>
                    {services.map(service => (
                        <ServiceLink key={service.slug} to={`/services`}>
                            <ServiceContent>
                                <ServiceLeftContent>
                                    <ServiceName>
                                        {service.name}
                                    </ServiceName>
                                    <ServiceDefinition>
                                        {service.definition}
                                    </ServiceDefinition>
                                </ServiceLeftContent>
                                <ServiceRightContent>
                                    <ServiceShortDescription>
                                        {service.shortDescription}
                                    </ServiceShortDescription>
                                    <ServiceLearnMore>
                                        Learn More
                                    </ServiceLearnMore>
                                </ServiceRightContent>
                            </ServiceContent>
                        </ServiceLink>
                    ))}
                </Container>
            </ServicesContent>
            <Waves>
                <SubWave bottom src={bottomSubWaveURL} />
                <MainWave src={bottomMainWaveURL} />
            </Waves>
        </ServicesContainer>
    );
}