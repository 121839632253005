import React from "react";
import styled from "@emotion/styled";

const Underline = styled.span<any>`
    display: inline-block;
    border-bottom: 2px solid ${props => props.theme.secondaryColor};
    white-space: nowrap;
`;

export interface IUnderlinerProps {
    text: string;
    underline: string;
}

export default function Underliner(props: IUnderlinerProps): JSX.Element {
    const {text, underline} = props;

    const i = text.indexOf(underline);

    if (i === -1) return (<span>{text}</span>);

    return (
        <span>
            {text.slice(0, i)}
            <Underline>{underline}</Underline>
            {text.slice(i + underline.length)}
        </span>
    );
}