import React from "react";
import styled from "@emotion/styled";

import Container from "./container";
import {md} from "../utils/breakpoints";

const PageHeaderContainer = styled.div`
    margin: 80px 0 0;
`;

const TitleSubtext = styled.h2<any>`
    font-size: 20px;
    color: ${props => props.theme.secondaryColor};
    font-family: ${props => props.theme.contentFontFamily};
    font-weight: 400;
    margin: 0 0 10px;
`;

const Title = styled.h1<any>`
    font-size: 48px;
    color: ${props => props.theme.primaryColor};
    font-family: ${props => props.theme.headerFontFamily};
    font-weight: 400;
    margin: 0 0 40px;
`;

const Description = styled.p<any>`
    margin: 0;
    color: ${props => props.theme.primaryColor};
    font-size: 18px;
    line-height: 1.4;
    white-space: pre-wrap;
`;

const Seperator = styled.div<any>`
    height: 1px;
    width: 140px;
    background: ${props => props.theme.borderColor};
    margin: 60px auto 0;
    display: none;

    @media (max-width: ${md}px) {
        display: block;
    }
`;

export interface IPageHeaderProps {
    title: string;
    titleSubtext: string;
    description?: string;
    noSeperator?: boolean;
}

export default function PageHeader(props: IPageHeaderProps): JSX.Element {
    const {title, description, titleSubtext, noSeperator} = props;

    return (
        <PageHeaderContainer>
            <Container> 
                <TitleSubtext>{titleSubtext}</TitleSubtext>
                <Title>{title}</Title>
                {description && <Description>{description}</Description>}
                {!noSeperator && (
                    <Seperator />
                )}
            </Container>
        </PageHeaderContainer>
    );
}